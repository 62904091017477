<script>
	import { Router, Route, Link } from "svelte-routing";
	import { Styles,
		Button,
	 	Table,
		Icon,
		Alert,
		Tooltip,
		Toast} from 'sveltestrap';
	import { api_url, path, errorColor } from "../ConfigService.js";
	import Loading from "../components/Loading.svelte";
	import { copy } from 'svelte-copy';
	
	export let zip="";
	export let auth;
	export let id;
	export let params="?id="+id+"&auth="+auth;
	export let isOpen=false;

	export let data = (async () => {
		const resp = await fetch($api_url+"order/"+params);
		return await resp.json()
	})()

	function removeSuffix(nm) {
			if(nm && nm!=null) {
				nm=nm.replaceAll(".jpg", "");
				nm=nm.replaceAll(".JPG", "");
				return nm;
			}
			return "";
	}
	
	function addToZip(file) {
		zip+="&files[]="+file;
		return "";
	}
	
	function getQrAccountNumber(c) {
		let c2=c.split("/");
		return "accountNumber="+c2[0]+"&bankCode="+c2[1];
	}

</script>
{#await data}
	<Loading />
{:then data}
   {#if data.order.uhrazeno=="1"}
   	<Alert color="success">
	    <h4 class="alert-heading">Vaše objednávka je již uhrazena! Děkujeme.</h4>
	    {#if data.order.datum} 
	    	Uhrazeno dne: {data.order.datum}
	    {/if}
	 </Alert>
   {:else}
   	
   	<Alert color="danger">
	    <h4 class="alert-heading">Objednávka nebyla dosud uhrazena.</h4>
	    <br />
	    {#if data.order.platba=="hotove"}
	    	Zvolili jste platbu v hotovosti. V případ, že chcete změnit typ úhrady, kontaktujte nás prosím.
	    {:else}
	    	<p class="txtbiger">
		    {#if data.cislouctu}
		    	Částku {data.order.cena},-Kč
		    	<br />
		    	Můžete zaslat na účet: <a href="#" use:copy="{data.cislouctu}" title="kopírovat číslo účtu">{data.cislouctu}</a>
		    	<br />
		    	Variabilní symbol: {data.order.id}
		    {/if}
		    <br /><br /><br />
		    {#if data.order.platba=="platebniBrana"}
			    <a href="#todo" class="alert-link">
			      <a href="/api/payment/?id={data.order.id}&price={data.order.cena}">Nebo můžete zkusit znovu zaplatit přes platební bránu</a>
			    </a>
		    {/if}
		    <br /><br />
		</p>
		{#if data.cislouctu} 
	    		<p class="float-right" style="width:200px;">
		    		<img alt="QR platba" src="https://api.paylibo.com/paylibo/generator/czech/image?{getQrAccountNumber(data.cislouctu)}&amount={data.order.cena}.00&currency=CZK&vs={data.order.id}&message={window.location.host}&size=150" />
		    	</p>
		{/if}
		<br style="clear:both" />
	    {/if}
	 </Alert>
   {/if}
	
   {#if data.photo}
    {#if data.photo.length>0}
	<b>Níže si můžete stáhnout zakoupené fotografie.</b><br /><br />
	<Table hover style="width:85%; max-width:800px; margin:auto;">
	<thead>
		<tr>
			<th>Fotografie</th>
			<th>Stažení</th>
		</tr>
	</thead>
	<tbody>
		{#if data.photo}
			{#each data.photo as p, z}
				<tr>
					<td>
						{removeSuffix(p['fs_foto.file'])}
					</td>
					<td>	
						{#if p['exists']=="1"}
							<a href="{$api_url}order/download/{params}&file={p['fs_foto.file']}" download on:click="{()=>isOpen=true}">stáhnout</a>
							{addToZip(p['fs_foto.file'])}
						{:else}	
							v přípravě
						{/if}
					</td>
				</tr>
			{/each}
		{/if}
		</tbody>
		</Table>
		<br />
		{#if zip}
			<a href="{$api_url}order/zip/{params}{zip}" download on:click="{()=>isOpen=true}"><Button color="primary">Stáhnout vše jako ZIP</Button></a>
		{/if}
     {/if}
    {/if}

{/await} 	
<div style="position:absolute; top:10px; right:10px;">
	<Toast autohide body deelay="4000" header="Vyčkejte prosím" {isOpen} on:close={() => (isOpen = false)}>
	  Stahování začne za malou chvilku.
	</Toast>
</div>


<style>
	.img-box { min-width:300px; max-width:500px; float:left; margin:0 10px 7px; }
	.img-box img { max-width:490px; }
	.variant-box { width:300px; float:left; margin:0 10px 7px; }
	.iconButton { text-align:center; margin:auto; font-size:20px; }
	.iconButton i { width:40px; margin:auto; }
	.link { border-bottom:1px black dashed; cursor:pointer; }
	.click { cursor:pointer; }
	tr:nth-child(2n) { background-color: rgba(0,0,0,.03); }
	.txtcenter { text-align: center; }
	.txtbiger { font-size:120%; min-width:250px; float:left; }
</style>
